.app__publications {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centering the content */
}

.app__publication-filter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 4rem 0 2rem;

  .app__publication-filter-item {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: #fff;
    color: #000;
    font-weight: 800;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0.5rem;
    &:hover {
      background-color: var(--secondary-color);
      color: #fff;
    }
  }

  .item-active {
    background-color: var(--secondary-color);
    color: #fff;
  }
}

.app__publication-portfolio {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly; /* Distribute items horizontally */
  align-items: flex-start; /* Align items at the top */
  gap: 2rem; /* Adds space between items */
  margin-bottom: 4rem;
}

.app__publication-item {
  width: 270px;
  flex-direction: column;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #fff;
  color: #000;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    margin: 1rem;
  }

  @media screen and (min-width: 2000px) {
    width: 400px;
    padding: 1.25rem;
    border-radius: 0.75rem;
  }
}

.app__publication-img {
  width: 100%;
  height: 230px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    object-fit: cover;
  }

  @media screen and (min-width: 2000px) {
    height: 350px;
  }
}

.app__publication-content {
  padding: 0.5rem;
  width: 100%;
  position: relative;
  flex-direction: column;
  display: flex; /* Ensure it's a flex container */
  justify-content: space-between;
  h4 {
    margin-top: 1rem;
    line-height: 1.5;
  }

  .app__publication-tag {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    text-align: center;
  }
}

body {
  overflow-y: auto;
}

.articles {
  max-width: 25%;  /* You can change this to a smaller value like 40% or 30% for a smaller image */
  height: auto;     /* Keeps the aspect ratio */
  margin: 0 auto;   /* Centers the image */
  display: block;   /* Ensures block display for proper centering */
}

.pagination-dots {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: #007BFF;
}

.dot:hover {
  background-color: #0056b3;
}

.group-selection {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.latest-research-button {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background-color: #fff;
  color: #000;
  font-weight: 800;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 0.5rem;
  border: none; // Removes the border

  &:hover {
    background-color: var(--secondary-color);
    color: #fff;
  }
}
