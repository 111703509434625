.app__personal {
    width: 100%;
    padding: 20px;
    text-align: center;
  
    /* Page Title */
    .page-title {
      font-size: 36px;
      margin-bottom: 20px;
      color: #333;
    }
  
    /* Section Title */
    .section-title {
      font-size: 28px;
      margin-bottom: 20px;
      color: #555;
    }
  
  
      /* Dots Container */
      .dots-container {
        display: flex;
        justify-content: center;
        gap: 10px;
        margin-top: 20px;
  
        .dot {
          height: 12px;
          width: 12px;
          background-color: gray;
          border-radius: 50%;
          cursor: pointer;
          transition: background-color 0.3s;
  
          &.active {
            background-color: #007bff;
          }
        }
      }
    }
  

  .animated-underline {
    position: relative;
    display: inline-block;
    font-size: 28px;
    font-weight: bold;
    color: #333;
  
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -5px;
      width: 100%;
      height: 3px;
      background: #007bff;
      transform: scaleX(0);
      transform-origin: right;
      transition: transform 0.3s ease-in-out;
    }
  
    &:hover::after {
      transform: scaleX(1);
      transform-origin: left;
    }
  }

  .bio-container {
    background: #f5f5f5;
    padding: 4rem;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    max-width: 900px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    overflow: hidden;
  }
  
  .bio-text {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 1.2rem;
    color: #333;
    margin-top: 2rem;
  }
  
  h2 {
    font-size: 2.5rem;
    font-weight: 700;
    color: #007bff;
    text-align: center;
  }
  
  .bio-container:hover .bio-text {
    color: #007bff; // Highlight text on hover for interactivity
  }
  

  .youtube-channel-viewer {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    position: relative; /* Ensure it stays in place */
    z-index: 1; /* Keep it above other elements */
  }
  
  .video-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    overflow: hidden; /* Prevent overflow */
  }
  
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }