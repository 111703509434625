.test {
  background-color: grey;

  // disable the default padding styles

}



.title {
  font-size: 4em;
  text-align: center;
  font-family: "Bebas Neue", sans-serif;
}

h3 {
  padding-top: 0.25em;
}

.vertical-timeline-element-content {
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
    0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
  padding: 2em 3em !important;
}

.date {
  color: black !important;
}


#description {
  margin: 1.5em 0 2em 0;
}

.button {
  text-decoration: none;
  padding: 0.5em 1em;
  border-radius: 5px;
  color: white;
}

.anotherbutton {
  text-decoration: none;
  padding: 0.5em 1em;
  margin-left: 8vw;
  border-radius: 5px;
  color: white;
  @media screen and (max-width: 750px) {
    margin-left: 0vw;
    margin-top: 3vh;
  }
}

.evenanotherbutton {
  text-decoration: none;
  padding: 0.5em 1em;
  margin-left: 3vw;
  border-radius: 5px;
  color: white;
  @media screen and (max-width: 750px) {
    margin-left: 0vw;
    margin-top: 3vh;
  }
}

.workButton {
  background-color: #06d6a0;
}

.workButton:hover {
  background-color: #0ac593;
}

.schoolButton {
  background-color: #f9c74f;
}

.schoolButton:hover {
  background-color: #f3bc3c;
}

@media only screen and (max-width: 1700px) {
  .vertical-timeline-element-date {
    display: block !important;
    float: none !important;
    color: rgb(44, 44, 44);
    margin-top: 1.5em;
  }
}

.certificate {
padding-top: 50px;
padding-bottom: 50px;
 display: block;
 margin-left: auto;
 margin-right: auto;

 width:40vw;
 height:40vh;



}
.allbuttons {
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: row;
  @media screen and (max-width: 750px) {
    flex-direction: column;
  }
}

