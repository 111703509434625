
.app__header {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 2rem 2rem 0rem;
  position: relative; /* Keep the relative positioning context */
  align-items: center; /* Center content in the row */
  
  @media screen and (min-width: 1200px) {
    flex-direction: row; /* Align header content in a row on larger screens */
    align-items: center; /* Center vertically within the row */
    justify-content: center; /* Center horizontally within the row */
  }



  .app__header-info img {
    max-width: 100%;
    height: auto;
    margin-right: 1rem; /* Space out the image from other elements */
  }

  @media screen and (max-width: 1200px) {
    .app__header-info {
      flex-direction: column; /* Stack items vertically on smaller screens */
      margin-right: 0rem;
      align-items: center; /* Center items vertically on smaller screens */
    }
  }
}

.app__header-badge {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  .badge-cmp,
  .tag-cmp {
    padding: 1rem 2rem;
    background: var(--white-color);
    border-radius: 15px;
    flex-direction: row;
    width: auto;

    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }

  .tag-cmp {
    flex-direction: column;
    margin-top: 3rem;

    p {
      width: 100%;
      text-transform: uppercase;
      text-align: right;
    }
  }

  span {
    font-size: 2.5rem;

    @media screen and (min-width: 2000px) {
      font-size: 5rem;
    }
  }

  @media screen and (max-width: 1200px) {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.app__header-circles {
  flex: 0.75;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  height: 100%;
  margin-left: 1rem;

  div {
    width: 100px;
    height: 100px;
    border-radius: 50%;

    background: var(--white-color);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

    img {
      width: 60%;
      height: 60%;
    }
  }

  // circles with different height & width

  div:nth-child(1) {
    width: 80px;
    height: 80px;
  }

  div:nth-child(2) {
    margin: 1.75rem;
    width: 100px;
    height: 100px;
  }

  div:nth-child(3) {
    width: 70px;
    height: 70px;
  }

  @media screen and (min-width: 2000px) {
    div:nth-child(2) {
      width: 400px;
      height: 400px;
    }

    div:nth-child(3) {
      width: 170px;
      height: 170px;
    }

    div:nth-child(1) {
      width: 200px;
      height: 200px;
    }
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 0;

    div {
      margin: 1rem;
    }
  }
}

.app__header-img {
  flex: 1;
  height: 100%;
  margin-left: 1vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  img {
    width: 40%;
    object-fit: contain;
    z-index: 1;
  }

  .overlay_circle {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
  }


}



.wrapper{

  transform: rotate(90deg);
  @media screen and (max-width: 480px) {
    display: none;
    visibility: hidden;

  }
  @media screen and (max-width: 750px) {
    display: none;
    visibility: hidden;

  }
}

.loader{

  position: relative;
  width: 250px;
  height: 88px;
}

.wave{
  display: flex;
  justify-content: space-between;
  position: absolute;

  height: 100%;
  width: 100%;

}

.wave > div{
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 100%;
}

.wave > div::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #aaaaaa;
  border-radius: 50%;
}

.top-wave > div::before {
  background-color: #ca0018;
}

.top-wave > div{
  animation: move 3s ease-in-out infinite reverse;

 }

.top-wave > div::before{
  animation: grow 3s linear infinite reverse;
}

.bottom-wave > div{
  animation: move 3s ease-in-out infinite;
 }

.bottom-wave > div::before{
  animation: grow 3s linear infinite;
}

.wave > div:nth-child(10){
  animation-delay: 0s;
}
.wave > div:nth-child(9){
  animation-delay: -0.1s;
}
.wave > div:nth-child(8){
  animation-delay: -0.2s;
}
.wave > div:nth-child(7){
  animation-delay: -0.3s;
}
.wave > div:nth-child(6){
  animation-delay: -0.4s;
}
.wave > div:nth-child(5){
  animation-delay: -0.5s;
}
.wave > div:nth-child(4){
  animation-delay: -0.6s;
}
.wave > div:nth-child(3){
  animation-delay: -0.7s;
}
.wave > div:nth-child(2){
  animation-delay: -0.8s;
}
.wave > div:nth-child(1){
  animation-delay: -0.9s;
}


.bottom-wave > div:nth-child(10){
  animation-delay: 0.75s;
}
.bottom-wave > div:nth-child(9){
  animation-delay: 0.65s;
}
.bottom-wave > div:nth-child(8){
  animation-delay: 0.55s;
}
.bottom-wave > div:nth-child(7){
  animation-delay: 0.45s;
}
.bottom-wave > div:nth-child(6){
  animation-delay: 0.35s;
}
.bottom-wave > div:nth-child(5){
  animation-delay: 0.25s;
}
.bottom-wave > div:nth-child(4){
  animation-delay: 0.15s;
}
.bottom-wave > div:nth-child(3){
  animation-delay: 0.05s;
}
.bottom-wave > div:nth-child(2){
  animation-delay: -0.05s;
}
.bottom-wave > div:nth-child(1){
  animation-delay: -0.15s;
}


@keyframes move{
  0%{
    transform: translateY(0px);
  }
  25%{
    transform: translateY(88px);
  }
  50%{
    transform: translateY(0px);
  }
  75%{
    transform: translateY(88px);
  }
  100%{
    transform: translateY(0px);
  }

}

@keyframes grow{
  0%, 50%, 75%, 100% {
    transform: scaleX(0.7) scaleY(0.7);
  }
  10%, 60% {
    transform: scaleX(1) scaleY(1);
  }
  35%, 85% {
    transform: scaleX(0.4) scaleY(0.4);
  }
}





.rnewhead-text {
  font-size: 2.5rem;
  font-weight: 800;
  color: var(--black-color);
  z-index: 100;

  width: -moz-fit-content;
  width: fit-content;
  position:absolute;
  left:50px;
  top:150px;

  span {
    color: var(--secondary-color);
  }


}
.rnewerhead-text {
  font-size: 2rem;
  font-weight: 800;
  color: var(--black-color);
  z-index: 100;
  position:absolute;
  left:15px;
  top:200px;


  span {
    color: var(--secondary-color);
  }


}
.rnewesthead-text {
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--black-color);
  z-index: 100;
  position:absolute;
  left:10px;
  top:250px;


  span {
    color: var(--secondary-color);
  }


}



.waving-hand {
  animation-name: wave-animation;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
  z-index: 100;

}

@keyframes wave-animation {
  0% { transform: rotate( 0.0deg) }
  15% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
  30% { transform: rotate(-8.0deg) }
  40% { transform: rotate(14.0deg) }
  50% { transform: rotate(-4.0deg) }
  60% { transform: rotate(10.0deg) }
  70% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
 100% { transform: rotate( 0.0deg) }
}

.rtyping{
  color:#000000;
	z-index:3;
	font-size: 25px;
	width:100px; /* or whatever width you want. */
	max-width:100px; /* or whatever width you want. */
  position:absolute;
  left:30px;
  top:290px;


}


.coding {
 width:20vw;
 height:20vh;
 @media screen and (max-width: 1200px) {
   width:30vw;
   height:30vh;

 }


}



.largep-text {
  font-size: 1.8rem;
  text-align: left;
  color: var(--gray-color);
  line-height: 1.5;

  @media screen and (min-width: 2000px) {
    font-size: 1.75rem;
  }
}


.medp-text {
  font-size: 1.2rem;
  text-align: left;
  color: var(--gray-color);
  line-height: 1.5;

  @media screen and (min-width: 2000px) {
    font-size: 1.75rem;
  }
}

.app__quote-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2rem 0; /* Add some padding */
  margin-top: auto; /* Push to the bottom of the parent container */
}

.app__quote {
  text-align: center;
  font-size: 1.4rem;
  font-style: italic;
  color: #444;
  padding: 1rem 2rem;
  max-width: 60%;
  background: rgba(255, 255, 255, 0.15);
  border-left: 4px solid #0077b6;
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}