



.app__summary {
  flex: 1;
  width: 100%;
  flex-direction: column;
}


/* Summary.scss */
#summary {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.head-text {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
}

.viewer-container {
  display: flex;
  width: 100%;
  max-width: 1200px; /* Adjust as needed */
}

.controls {
  width: 250px; /* Adjust as needed */
  margin-right: 20px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.controls label {
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
}

.controls select {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.interaction-checkboxes {
  margin-top: 20px;
}

.interaction-checkboxes label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
}

.ngl-viewer {
  flex-grow: 1;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
}

.custom-link {
  color: black;
}

#fireworksHolder {
  overflow: hidden; /* Prevent scrolling on this container */
}
