.app__navbar {
  width: 100%;
  height: 80px; // Ensure the navbar has a fixed height
  overflow: hidden; // Prevent elements from overflowing

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1rem 2rem;
  background: rgba(255, 255, 255, 0.25);
  // box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  position: fixed;
  z-index: 2000000;
}

.app__navbar-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 184.4px;
    height: 60px;

    @media screen and (min-width: 2000px) {
      width: 180px;
      height: 40px;
    }
  }
}

.app__navbar-links {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index:200000;
  list-style: none;

  li {
    margin: 0 1rem;
    cursor: pointer;

    flex-direction: column;

    div {
      width: 5px;
      height: 5px;
      background: transparent;
      border-radius: 50%;

      margin-bottom: 5px;
    }

    a {
      color: var(--gray-color);
      text-decoration: none;
      flex-direction: column;

      text-transform: uppercase;
      font-weight: 500;

      transition: all 0.3s ease-in-out;

      &:hover {
        color: var(--secondary-color);
      }
    }

    &:hover {
      div {
        background: var(--secondary-color);
      }
    }
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
}



@keyframes spinning {
    from {
        transform: rotateX(0);
    }

    to {
        transform: rotateX(360deg);
    }
}

$spintime: 1s;

.spinning {
    animation: spinning $spintime infinite;
}


$background: #222;
$center-background: #999;

$border-color: #666;
$border-size: 3px;

$border-color-c2: #555;
$border-size-c2: 2px;

$size: 50px;
$size-c2: 30px;
$size-center: 10px;

$t-wrap-1: 15s;
$t-wrap-2: 8s;

$t-ho-c1: 8s;
$t-ve-c1: 6s;

$t-ho-c2: 4s;
$t-ve-c2: 3s;

$t-el-c1-a: 3s;
$t-el-c1-b: 1s;
$t-el-c2-a: 2s;
$t-el-c2-b: 30s;


.container {
  position: absolute;
  left: 10px;  /* Keep it inside the navbar on the left */
  top: 30%;  /* Center it vertically within the navbar */
  transform: translateY(-50%) scale(0.8); /* Adjust positioning & scale */
  max-height: 100%; /* Prevent it from exceeding navbar height */
}

/* Reduce animation size for smaller screens */
@media screen and (max-width: 1024px) {
  .container {
    transform: scale(0.6); // Shrink animation for smaller screens
    left: 5px;
    bottom: 5px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    display: none; // Hide spinning animation on very small screens if needed
  }
}

.wrap, .circle{
    animation: spinning 500ms linear;

    width:$size;
    height:$size;

    margin-top:0px;
    position:absolute;
}

.circle{
    position:absolute;
    border:$border-size solid $border-color;
    border-radius: $size;

}

.circle.c2,
.circle.center{
    border:$border-size-c2 solid $border-color-c2;
    width: $size-c2;
    height: $size-c2;
    top: round( ($size - $size-c2) / 2 );
    left: round( ($size - $size-c2) / 2 );
}
.circle.center{
    background: $center-background;
    width: $size-center;
    height: $size-center;
    top: round( ($size - $size-center) / 2 );
    left: round( ($size - $size-center) / 2 );
    box-shadow: 0 0 5px #fff;
}

.wrap-electron{
  border:0px solid  #fff;
  animation: electron $t-el-c1-a linear infinite;

}

.electron{
  width:8px;
  height:8px;
  background: $border-color;
  left:50%;
  margin-left:-8px;
  border:none;
  top:-7px;

}

.c2 .wrap-electron{
  animation: electron $t-el-c2-a linear infinite;

}

.c2 .electron{
  top:-6px;
}




.wrap{
    border:0px solid $border-color;
    animation: lateral $t-wrap-1 ease-in-out infinite;

}

.wrap.r{
    animation: lateralRevert $t-wrap-2 linear infinite;

}


.vertical{
    animation: vertical $t-ho-c1 linear infinite;

}
.horizontal{
    animation: horizontalRevert $t-ve-c1 linear infinite;

}

.vertical.c2{
    animation: vertical $t-ho-c2 linear infinite;

}


.horizontal.c2{
    animation: horizontalRevert $t-ve-c2 linear infinite;

}

@keyframes electron {
  from {
      transform: rotateZ(0deg);
  }
  to {
      ransform: rotateZ(360deg);
  }
}



@keyframes horizontal {
  from {
      transform: rotateY(0deg);
  }
  to {
      transform: rotateY(360deg);
  }
}

@keyframes horizontalRevert {
  from {
      transform: rotateY(360deg);
  }
  to {
      transform: rotateY(0deg);
  }
}

@keyframes vertical {
  from {
      transform: rotateX(0deg);
  }
  to {
      transform: rotateX(360deg);
  }
}

@keyframes verticalRevert {
  from {
      transform: rotateX(360deg);
  }
  to {
      transform: rotateX(0deg);
  }
}


@keyframes lateral {
  from {
      transform: rotateZ(0deg);
  }
  to {
      transform: rotateZ(360deg);
  }
}

@keyframes lateralRevert {
  from {
      transform: rotateZ(360deg);
  }
  to {
      transform: rotateZ(0deg);
  }
}
